<template>
    <div>
        <Modal
        :enable-close="false"
            v-model="mostrarGestionDocumentos"
            :title="documentoTemplateSelected != null ? `Subir documento para ${documentoTemplateSelected.tipoDocumentoSubNombreVisible}`: 'Subir Nuevo Template'"
            :modal-style="{ 'min-width': '300px', 'max-width': '1140px' }"
            @before-open="generarLinkSubidaArchivos"
            @close="cerrarModal"
        >
            
            <CRow v-if="documentoTemplateSelected == null">
                <CCol col="3">
                    <multiselect class="multSelectGesto mb-2"
                        openDirection="below"
                        v-model="tipoSubDocumentoSelected"
                        deselect-label="" 
                        label="nombreVisibleSub"
                        placeholder="Documento"
                        select-label=""
                        selectedLabel=""
                        :options="tipoDocumentoSelected.tipoSubDocumentos" 
                        :searchable="true"
                        @input="seleccionarTipoDocumento"
                        :multiple="false" :taggable="true"
                        @tag="agregatTipoDoc"
                        :content="`Seleccionar Tipo de ${tipoDocumentoSelected.nombreVisible}`"
                        v-tippy="{ 
                            placement: 'top',
                            arrow: true, 
                            arrowType: 'round', 
                            animation:'fade',
                            theme: 'google',
                            followCursor : true,
                        }">
                    </multiselect>

                    <!--
                        <multiselect class="d-inline-block multSelectGesto"
                            v-model="tipoSubDocumentoSelected"
                            deselect-label="" 
                            label="nombreVisibleSub"
                            placeholder="Documento"
                            select-label=""
                            selectedLabel=""
                            :options="tipoDocumentoSelected.tipoSubDocumentos" 
                            :searchable="true"
                            :content="errorDctos==null?'Seleccionar Tipo Documento':errorDctos"
                            v-tippy="{ 
                                placement: 'top',
                                arrow: true, 
                                arrowType: 'round', 
                                animation:'fade',
                                theme: 'google',
                                followCursor : true,
                            }"
                            @input="seleccionarTipoDocumento">
                        </multiselect>
                    -->
                </CCol>
            </CRow>
                <CRow v-if="this.documentoTemplateSelected == null ? tipoSubDocumentoSelected != null : true">
                    <CCol>
                        <!--<button
                            type="button"
                            class="btn btn-danger float-right btn-is-option mb-2"
                            @click.prevent="empresa.isOption = !empresa.isOption">
                            <i class="fa fa-cog" aria-hidden="true"></i> Opciones
                        </button>-->

                            <file-upload
                                class="btn btnPrincipalVapp mr-2"
                                :post-action="linkSubir"
                                :headers="headers"
                                :extensions="''"
                                :accept="''"
                                :multiple="false"
                                :directory="false"
                                :size="0"
                                :thread="3"
                                :drop="false"
                                :drop-directory="false"
                                :add-index="addIndex"
                                v-model="archivosSubir"
                                @input-file="inputFile"
                                ref="upload">
                                <CIcon :content="$options.freeSet.cilPlus" />
                                Agregar Archivos
                            </file-upload>

                            <button
                                style="font-size: 11px;"
                                v-show="archivosSubir.length>0 && !isLoadingSubir"
                                type="button"
                                class="btn btn-danger mr-2"
                                @click.prevent="archivosSubir = []">
                                <CIcon :content="$options.freeSet.cilBan"/>
                                Quitar Documentos
                            </button>

                            <button
                                v-show="archivosSubir.length>0"
                                style="font-size: 11px;"
                                type="button"
                                class="btn btnPrincipalVapp"
                                v-if="!$refs.upload || !$refs.upload.active"
                                @click.prevent="subirTodoActivar(empresa);">
                                <CIcon :content="$options.freeSet.cilCloudUpload"/>
                                Subir todo
                            </button>

                            <button
                                style="font-size: 11px;"
                                type="button"
                                class="btn btn-danger"
                                v-else
                                @click.prevent="$refs.upload.active = false">
                                <CIcon :content="$options.freeSet.cilXCircle"/>
                                Detener todo
                            </button>

                        <div class="table-responsive">
                            <a-table
                                row-key="name"
                                :columns="columnasSubidaArchivos" 
                                :data-source="archivosSubir"
                                :scroll="{ y: 400 }"
                                class="tableInterior mt-3" 
                                size="small">
                                <span slot="nombreArchivo" slot-scope="archivo">
                                    <CRow>
                                        <CCol>{{archivo.name}}</CCol>
                                    </CRow>
                                </span>
                                <template slot="progreso" slot-scope="archivo">
                                    <CRow>
                                        <CCol>
                                            <CProgress 
                                            :value="parseFloat(archivo.progress)"
                                            :precision="2" 
                                            show-percentage 
                                            :animated="true"
                                            :color="(archivo.error)?'danger':'success'"
                                            />
                                        </CCol>
                                    </CRow>
                                </template>
                                <template slot="tamanio" slot-scope="archivo">
                                    {{archivo.size | prettyBytes}}
                                </template>

                                <template slot="velocidad" slot-scope="archivo">
                                    {{ archivo.speed | prettyBytes }}/s
                                </template>

                                <template slot="estado" slot-scope="archivo">
                                    <span v-if="archivo.error" style="color:#7E1146">
                                        <span v-if="archivo.error === 'size'">
                                            Archivo demasiado pequeño
                                        </span>
                                        <span v-else>{{ archivo.response.mensajeGeneral }}</span>
                                    </span>
                                    <!--<CBadge v-if="archivo.error" color="danger">
                                        <span v-if="archivo.error === 'size'">
                                            Archivo demasiado pequeño
                                        </span>
                                        <span v-else>{{ archivo.response }}</span>
                                    </CBadge>-->
                                    <span v-else-if="archivo.success" style="color: #2eb85c">
                                        {{archivo.response.mensajeGeneral}}
                                    </span>
                                    <CBadge v-else-if="archivo.active" color="warning" >
                                        Subiendo
                                    </CBadge>
                                    <CBadge v-else color="secondary" >
                                        En espera
                                    </CBadge>
                                </template>

                                <template slot="acciones" slot-scope="archivo">
                                    <CButtonGroup class="mx-1">
                                        <CButton
                                            v-if="archivo.active" 
                                            title="Cancelar subida archivo"
                                            color="danger" 
                                            size="sm"
                                            @click.prevent="archivo.active? $refs.upload.update(archivo, { error: 'Cancelado' }): false">
                                            <CIcon name="cil-x-circle"/>
                                        </CButton>

                                        <CButton
                                            v-else-if="archivo.error && archivo.error !== 'compressing' && $refs.upload.features.html5"
                                            title="Recargar archivo"
                                            color="info" 
                                            size="sm"
                                            @click.prevent="archivo.active || $refs.upload.update(archivo, {active: true,error: '',progress: '0.00'})">
                                            <CIcon :content="$options.freeSet.cilLoopCircular"/>
                                        </CButton>

                                        <CButton
                                            v-else
                                            title="Subir archivo"
                                            color="success" 
                                            size="sm"
                                            @click.prevent="archivo.success || archivo.error === 'compressing'? false: $refs.upload.update(archivo, { active: true })">
                                            <CIcon :content="$options.freeSet.cilCloudUpload"/>
                                        </CButton>

                                        <CButton
                                            title="Quitar archivo"
                                            color="danger" 
                                            size="sm"
                                            @click.prevent="$refs.upload.remove(archivo)">
                                            <CIcon :content="$options.freeSet.cilTrash"/>
                                        </CButton>
                                        
                                    </CButtonGroup>
                                </template>
                                
                                
                            </a-table>
                        </div>
                    </CCol>
                </CRow>
            <CRow>
                <CCol sm="12" md="12" lg="12" align="right">
                    <CButton
                    md="3"
                    class="btnPrincipalVapp m-3"
                    style="margin: 20%"
                    @click="cerrarModal"
                    >Cerrar</CButton>
                </CCol>
            </CRow>
        </Modal>
    </div>
    
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import cons from "@/const";
    import Multiselect from "vue-multiselect";
    import axios from "axios";

    import VueModal from "@kouts/vue-modal";
    import "@kouts/vue-modal/dist/vue-modal.css";

    import { freeSet } from "@coreui/icons";
    import FileUpload from "vue-upload-component";

    
    import ModalPdfViewDocs from "./ModalPdfViewDocs";

    export default {
        name: "GestionDocumentos",
        freeSet,
        props: {
            mostrarGestionDocumentos: Boolean,
            empresa: Object,
            periodoSelected: Object,
            mesSelected: Object,
            periodoCerrado: Boolean,
            trabajadores: Array,
            loading: Boolean,
            paginacion: Object,
            totalTrabajadores: Number,
            totalTrabajadoresDocumentos: Number,
            tipoDocumentoSelected: Object,
            isLoadingGenerarDocumento: Boolean,
            padre: Object,
            mostrarPeriodo: Boolean,
            documentoTemplateSelected: Object
        },
        components: {
            Multiselect,
            'Modal': VueModal,
            FileUpload,
        },
        data() {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                urlBase: cons.port+"://" + cons.ipServer,

                subidaDocsID: null,
                linkSubir: "",
                archivosSubir: [],
                isLoadingSubir: false,

                tipoSubDocumentoSelected: null,
                errorDctos: null,


                documentosTemplate: [],

                columnasDocumentosTemplate: [
                    { title: 'Nombre Documento Template', scopedSlots: { customRender: 'nombreDocumento' }, width: '30%', align: 'left' ,ellipsis: false},
                    { title: 'Tipo Documento', scopedSlots:{ customRender: 'tipoDocumento' }, width: '30%', align: 'center', ellipsis: false},
                    { title: 'Documento', scopedSlots: { customRender: 'documento' }, width: '40%', align: 'left', ellipsis: false},
                ],
                columnasSubidaArchivos: [
                    { title: 'Nombre Archivo', scopedSlots: { customRender: 'nombreArchivo' }, width: '20%', align: 'left' ,ellipsis: false},
                    { title: 'Progreso', scopedSlots:{ customRender: 'progreso' }, width: '20%', align: 'center', ellipsis: false},
                    { title: 'Tamaño', scopedSlots: { customRender: 'tamanio' }, width: '10%', align: 'left', ellipsis: false},
                    { title: 'Velocidad', scopedSlots: { customRender: 'velocidad' }, width: '10%', align: 'center', ellipsis: false},
                    { title: 'Estado', scopedSlots: { customRender: 'estado' }, width: '20%', align: 'center', ellipsis: false},
                    { title: 'Acciones', scopedSlots: { customRender: 'acciones' }, width: '20%', align: 'center', ellipsis: false},
                ],
                
                files: [],
                accept: "",
                extensions: "",
                // extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
                // extensions: /\.(gif|jpe?g|png|webp)$/i,
                minSize: 1024,
                size: 0,
                multiple: true,
                directory: false,
                drop: true,
                dropDirectory: true,
                addIndex: false,
                thread: 3,
                name: "file",
                postAction: cons.port+"://" + cons.ipServer + "/upload_file",
                putAction: "",
                headers: {
                    Authorization: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                },
                data: {
                    "test": "asd",
                },
                autoCompress: 1024 * 1024,
                uploadAuto: false,
                isOption: false,
                addData: {
                    show: false,
                    name: "",
                    type: "",
                    content: "",
                },
                editFile: {
                    show: false,
                    name: "",
                },

            };
        },
        
        watch: { 
            "editFile.show"(newValue, oldValue) {
                if (!newValue && oldValue) {
                this.$refs.upload.update(this.editFile.id, {
                    error: this.editFile.error || "",
                });
                }
                if (newValue) {
                this.$nextTick(function () {
                    if (!this.$refs.editImage) {
                    return;
                    }
                    let cropper = new Cropper(this.$refs.editImage, {
                    autoCrop: false,
                    });
                    this.editFile = {
                    ...this.editFile,
                    cropper,
                    };
                });
                }
            },
            "addData.show"(show) {

                if (show) {
                this.addData.name = "";
                this.addData.type = "";
                this.addData.content = "";
                }
            },
        },
        created: function () {
        },
        beforeMount() {
            
            //this.getDocumentosTemplate()
            
        },
        mounted(){
        },
        methods: {
            getDocumentosTemplate: function(){
                let url =  this.urlBase + "/get_documentos_template/"
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "filtrarPorPeriodo": this.mostrarPeriodo,
                        "idEmpresa": this.empresa.idEmpresa,
                        "idTipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.idTipoDocumento : 'null',
                        "mes": (this.mesSelected != null) ? this.mesSelected.valor : null,
                        "anio": (this.periodoSelected != null) ? this.periodoSelected.valor : null
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.documentosTemplate = result.data.templateDocs
                    this.mostrarNotificaciones(1, result.data.mensajeGeneral)
                    
                }, error => {
                    this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                })
                .finally(() => {
                });
            },
            cerrarModal: function () {
                this.linkSubir = ""
                this.tipoSubDocumentoSelected = null
                this.archivosSubir = []

                this.padre.mostrarSubirArchivos = false;
            },
            agregatTipoDoc: function(tag){
                let td = {"idTipoDocumentoSub": 0, "nombreTipoDocumentoSub": tag, "nombreVisibleSub": tag, "idTipoDocumentos": this.tipoDocumentoSelected.idTipoDocumento }
                this.tipoDocumentoSelected.tipoSubDocumentos.push(td)
                this.tipoSubDocumentoSelected = td
                this.generarLinkSubidaArchivos()
            
            },
            seleccionarTipoDocumento: function(){
                this.generarLinkSubidaArchivos()
            },
            generarLinkSubidaArchivos: function(){
                //${urlBase}/subir_documento_template/${empresaSelected.idEmpresa}/${documento.idTemplateDocumento}/${documento.idTipoDocumentoSub}`"
                if(this.documentoTemplateSelected == null)
                    if(this.tipoSubDocumentoSelected == null)
                        this.mostrarNotificaciones(2, "Seleccione el tipo de Documento a subir")

                let idSub = this.documentoTemplateSelected != null ? this.documentoTemplateSelected.idTipoDocumentoSub : (this.tipoSubDocumentoSelected != null ? this.tipoSubDocumentoSelected.idTipoDocumentoSub : null)
                let idTemplate = this.documentoTemplateSelected != null ? this.documentoTemplateSelected.idTemplateDocumento : null
                this.linkSubir = `${this.urlBase}/subir_documento_template/${this.empresa.idEmpresa}/${this.tipoDocumentoSelected.idTipoDocumento}/${idSub}/${idTemplate}`
                
            },
            subirDocumento: function(documento){
                this.$refs.upload.active = true
            },
            subirTodoActivar: function(empresa){
                if(this.subidaDocsID == null){
                    
                    this.$refs.upload.active = true
                    this.validarDocsError(empresa)

                    var idInt = setInterval(function(){
                        this.validarDocumentosSubidos(empresa);
                    }.bind(this), 1000);
                    this.subidaDocsID = idInt
                }

            },
            validarDocsError: function(empresa){
                this.archivosSubir.forEach(archivo => {
                    if(!archivo.success && archivo.error != ""){
                        this.$refs.upload.update(archivo, {active: true,error: '',progress: '0.00'})
                    }
                });

            },
            validarDocumentosSubidos: function(empresa){
                if(this.archivosSubir.length == 0){
                    clearInterval(this.subidaDocsID)
                    this.subidaDocsID = null
                }
                else{
                    var finalizados = 0
                    this.archivosSubir.forEach(archivo => {
                        if((!archivo.active && parseInt(archivo.progress) == 100) || (!archivo.active && archivo.error == "Cancelado") || (!archivo.active && archivo.success) || (!archivo.active && !archivo.success)){
                            finalizados++
                        }
                    })
                    if(finalizados == this.archivosSubir.length){
                        clearInterval(this.subidaDocsID)
                        this.subidaDocsID = null
                        //this.obtenerTrabajadores(true, empresa)
                    }
                }
            },
            inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    // Before adding a file
                    // 添加文件前
                    // Filter system files or hide files
                    // 过滤系统文件 和隐藏文件
                    if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                    return prevent();
                    }
                    // Filter php html js file
                    // 过滤 php html js 文件
                    if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                    return prevent();
                    }
                    // Automatic compression
                    // 自动压缩
                    if (
                    newFile.file &&
                    newFile.type.substr(0, 6) === "image/" &&
                    this.autoCompress > 0 &&
                    this.autoCompress < newFile.size
                    ) {
                    newFile.error = "compressing";
                    const imageCompressor = new ImageCompressor(null, {
                        convertSize: Infinity,
                        maxWidth: 512,
                        maxHeight: 512,
                    });
                    imageCompressor
                        .compress(newFile.file)
                        .then((file) => {
                        this.$refs.upload.update(newFile, {
                            error: "",
                            file,
                            size: file.size,
                            type: file.type,
                        });
                        })
                        .catch((err) => {
                        this.$refs.upload.update(newFile, {
                            error: err.message || "compress",
                        });
                        });
                    }
                }
                if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                    // Create a blob field
                    // 创建 blob 字段
                    newFile.blob = "";
                    let URL = window.URL || window.webkitURL;
                    if (URL && URL.createObjectURL) {
                    newFile.blob = URL.createObjectURL(newFile.file);
                    }
                    // Thumbnails
                    // 缩略图
                    newFile.thumb = "";
                    if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
                    newFile.thumb = newFile.blob;
                    }
                }
                },
                // add, update, remove File Event
                inputFile(newFile, oldFile) {
                    if (newFile && oldFile) {
                        // update
                        if (newFile.active && !oldFile.active) {
                        // beforeSend
                        // min size
                        if (
                            newFile.size >= 0 &&
                            this.minSize > 0 &&
                            newFile.size < this.minSize
                        ) {
                            this.$refs.upload.update(newFile, { error: "size" });
                        }
                        }
                        if (newFile.progress !== oldFile.progress) {
                        // progress
                        
                        }
                        if (newFile.error && !oldFile.error) {
                        // error
                        }
                        if (newFile.success && !oldFile.success) {
                        // success
                        }
                    }
                    if (!newFile && oldFile) {
                        // remove
                        if (oldFile.success && oldFile.response.id) {
                        // $.ajax({
                        //   type: 'DELETE',
                        //   url: '/upload/delete?id=' + oldFile.response.id,
                        // })
                        }
                    }
                    // Automatically activate upload
                    if (
                        Boolean(newFile) !== Boolean(oldFile) ||
                        oldFile.error !== newFile.error
                    ) {
                        if (this.uploadAuto && !this.$refs.upload.active) {
                        this.$refs.upload.active = true;
                        }
                    }
                },
                alert(message) {
                    alert(message);
                },
                onEditFileShow(file) {
                this.editFile = { ...file, show: true };
                this.$refs.upload.update(file, { error: "edit" });
                },
                onEditorFile() {
                if (!this.$refs.upload.features.html5) {
                    this.alert("Your browser does not support");
                    this.editFile.show = false;
                    return;
                }
                let data = {
                    name: this.editFile.name,
                };
                if (this.editFile.cropper) {
                    let binStr = atob(
                    this.editFile.cropper
                        .getCroppedCanvas()
                        .toDataURL(this.editFile.type)
                        .split(",")[1]
                    );
                    let arr = new Uint8Array(binStr.length);
                    for (let i = 0; i < binStr.length; i++) {
                    arr[i] = binStr.charCodeAt(i);
                    }
                    data.file = new File([arr], data.name, { type: this.editFile.type });
                    data.size = data.file.size;
                }
                this.$refs.upload.update(this.editFile.id, data);
                this.editFile.error = "";
                this.editFile.show = false;
                },
                // add folader
                onAddFolader() {
                if (!this.$refs.upload.features.directory) {
                    this.alert("Your browser does not support");
                    return;
                }
                let input = this.$refs.upload.$el.querySelector("input");
                input.directory = true;
                input.webkitdirectory = true;
                this.directory = true;
                input.onclick = null;
                input.click();
                input.onclick = (e) => {
                    this.directory = false;
                    input.directory = false;
                    input.webkitdirectory = false;
                };
                },
                onAddData() {
                    this.addData.show = false;
                    if (!this.$refs.upload.features.html5) {
                        this.alert("Your browser does not support");
                        return;
                    }
                    let file = new window.File([this.addData.content], this.addData.name, {
                        type: this.addData.type,
                    });
                    this.$refs.upload.add(file);
                },

        },
    };
</script>


<style scoped>

.tippy-tooltip.google-theme{
  background-color:#29235C;
  font-weight: normal;
}
.tippy-tooltip.google-theme .tippy-backdrop {
  
  background-color:#29235C;
}
.tippy-content{
  background-color: transparent;
}
</style>